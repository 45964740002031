// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// init "slideshow" swiper
new Swiper (".swiper-container.-slideshow", {
    autoplay: document.querySelectorAll(".swiper-container .swiper-slide").length > 1 ? 5000 : 0,
    speed: 150,
    loop: true,
    pagination: ".swiper-pagination",
    paginationClickable: true,
});

// init "scroller" swiper
new Swiper (".swiper-container.-scroller", {
    slidesPerView: "auto",
    spaceBetween: 70,
    speed: 150,
    loop: true,
    nextButton: ".swiper-button.-next",
    prevButton: ".swiper-button.-prev",
});
