// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

document.addEventListener("DOMContentLoaded", () => {
    const search_container = document.querySelector(".search-block_container");
    const search           = document.querySelector(".search-form_input[name=s]");
    const toggle           = document.querySelector(".panel-toggle[data-target=search]");
    const active_class     = "is-active";

    toggle.addEventListener("click", (e) => {
        e.preventDefault();

        if (search_container.classList.contains(active_class)) {
            search_container.classList.remove(active_class);
            toggle.focus();
        } else {
            search_container.classList.add(active_class);
            search.focus();
        }
    });
});
