// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const search_form   = document.getElementById("form_search");
const catalog_radio = document.getElementById("mode_catalog");
const website_radio = document.getElementById("mode_website");

catalog_radio.addEventListener("change", () => {
    if (catalog_radio.checked) {
        search_form.target = "_blank";
    } else {
        search_form.target = "_self";
    }
});

website_radio.addEventListener("change", () => {
    if (website_radio.checked) {
        search_form.target = "_self";
    } else {
        search_form.target = "_blank";
    }
});
